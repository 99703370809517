<template>
  <router-view></router-view> <!-- 現在表示されているコンポーネントがここに入る -->
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
