<template>
  <!-- ローディング画面 -->
  <LoadingScreen v-if="isLoading" />
  <div v-else>
    <PageNavigation />
    <div class="margin-section">
      <div>
        <InsertImage titlePage="TREATMENT" titlePageJP="治療案内"/>
      </div>
      <div class="half-block-content">
        <!-- パンくずリストを追加 -->
        <nav aria-label="breadcrumb" class="breadcrumb">
          <ul>
            <li>
              <router-link to="/">ホーム</router-link>
            </li>
            <li> <!-- すでにそのページにいる -->
              <router-link :to="{ path: '/', hash: '#treatment-category-list' }">治療案内</router-link> <!-- 遷移してから移動 -->
            </li>
          </ul>
        </nav>
        <h2>{{ treatmentCategoryName }}の治療一覧</h2>
        <div class="center">
          <img alt="Circle objects" src="@/assets/images/circle_objects.png" class="display-circle-obj" />
        </div>
        <div class="treatment-list-container content-padding">
          <div v-if="treatmentListData.length === 0" class="treatment-list">治療案内情報はありません。</div>
          <div v-else class="treatment-list">
            <ul>
              <li v-for="treatment in treatmentListData" :key="treatment.treatment_id" @click="navigateToTreatmentDetail(treatment.treatment_category, treatment.treatment_id)">
                <!-- リスト項目がクリックされたら、詳細ページに遷移 -->
                <a>
                  {{ treatment.treatment_name }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import treatmentService from '@/services/treatmentService';
import PageNavigation from '@/components/PageNavigation.vue';
import PageFooter from '@/components/PageFooter.vue';
import LoadingScreen from '@/components/LoadingScreen.vue';
import InsertImage from '@/components/InsertImage.vue';

export default {
  name: 'TreatmentListPage',
  components: {
    PageNavigation,
    PageFooter,
    LoadingScreen,
    InsertImage,
  },
  data() {
    return {
      treatmentListData: [],
      isLoading: true // ローディング状態
    };
  },
  computed: {
    treatmentCategoryName() {
      // URLパラメータから治療カテゴリ名を取得
      const treatmentCategory = this.$route.params.treatmentCategory;
      switch (treatmentCategory) {
        case 'general_internal_medicine':
          return '一般内科';
        case 'dermatology':
          return '皮膚科';
        case 'urology':
          return '泌尿器科';
        case 'exam_immunization':
          return '検査・予防接種';
        default:
          return '';
      }
    }
  },
  created() {
    // 遷移時にAPIを呼び出してデータを取得
    const treatmentCategory = this.$route.params.treatmentCategory;
    this.fetchTreatmentList(treatmentCategory);
  },
  watch: {
    // URLの治療カテゴリが変わった時にデータを再取得
    '$route.params.treatmentCategory'(newCategory) {
      this.fetchTreatmentList(newCategory);
    }
  },
  methods: {
    // APIを呼び出してデータを取得
    async fetchTreatmentList(treatmentCategory) {
      try {
        this.treatmentListData = await treatmentService.getTreatmentList(treatmentCategory); // BEからデータを取得
      } catch (error) {
        console.error('APIエラー:', error);
      } finally {
        // ローディング終了
        this.isLoading = false;
      }
    },
    navigateToTreatmentDetail(treatmentCategory, treatmentId) {
      // 治療案内のページに遷移
      this.$router.push(`/treatment/${treatmentCategory}/${treatmentId}`);
    },
  }
}
</script>

<style scoped>
/* ulとolのリストのスタイルをリセット */
.treatment-list ul, ol {
  padding: 0;
  margin: 0;
}

.treatment-list-container {
  display: flex;
}


/* PC用の3列表示 */
@media (min-width: 768px) {

}

@media (max-width: 768px) {

  .treatment-list-container {
    display: flexbox;
    justify-content: center;
  }

}

</style>