<template>
  <div>
    <div v-for="(news) in visibleNewsList" :key="news.news_id" class="news-item">
      <div class="title-date">
        <!-- タイトルを編集可能にするために input を使用 -->
        <div class="form-group">
          <label for="title">タイトル:</label>
          <input v-model="news.title" type="text" id="title" :placeholder="news.title" required />
        </div>
      </div>
      <div class="info-row">
        <p><small>日付: {{ news.date }}</small></p>
        <p><small>作成者: {{ news.create_info.user.user_name }}</small></p>
        <p><small>作成日: {{ formatDate(news.create_info.datetime) }}</small></p>
        <p><small>最終更新者: {{ news.update_info.user.user_name }}</small></p>
        <p><small>最終更新日: {{ formatDate(news.update_info.datetime) }}</small></p>
      </div>

      <!-- コンテンツを編集可能にするために textarea を使用 -->
      <div class="form-group">
        <label for="content">お知らせ内容:</label>
        <textarea v-model="news.content" id="content" required></textarea>
      </div>

      <!-- 編集ボタンと削除ボタン -->
      <div class="form-button">
        <button @click="handlePatchNews(news)" class="patch-btn">変更</button>
        <button @click="confirmDelete(news)" class="delete-btn">削除</button>
      </div>
    </div>
    <button v-if="hasMoreNews" @click="showMore" class="load-more">
      {{ isLoadingMore ? '読み込み中...' : 'さらに表示' }}
    </button>

    <!-- ConfirmModalを使用して削除確認 -->
    <ConfirmModal 
      :visible="isConfirmModalVisible" 
      :message="modalMessage" 
      @confirm="handleDeleteConfirmed" 
      @cancel="cancelDelete" 
    />

    <!-- SnackBarの表示 -->
    <SnackBar :message="snackBarMessage" />

  </div>
</template>

<script>
import ConfirmModal from '@/components/ConfirmModal'; // モーダルをインポート
import newsService from '@/services/newsService';
import { formatDate } from '@/utils/utils';
import SnackBar from '@/components/SnackBar';

export default {
  name: 'AdminNewsItem',
  components: {
    ConfirmModal,
    SnackBar,
  },
  props: {
    newsList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      newsService,
      visibleNewsList: [],
      visibleCount: 5,
      isLoadingMore: false,
      isConfirmModalVisible: false, // モーダルの表示制御
      newsToDelete: null, // 削除対象のニュース
      modalMessage: '', // モーダルに表示するメッセージ
      snackBarMessage: '', // スナックバーのメッセージ
    };
  },
  computed: {
    hasMoreNews() {
      return this.newsList && this.visibleNewsList.length < this.newsList.length;
    },
  },
  methods: {
    formatDate,
    initializeNews() {
      if (this.newsList) {
        this.visibleNewsList = this.newsList.slice(0, this.visibleCount);
      }
    },
    showMore() {
      if (!this.newsList) return;

      this.isLoadingMore = true;
      setTimeout(() => {
        const startIndex = this.visibleNewsList.length;
        const endIndex = startIndex + this.visibleCount;

        for (let i = startIndex; i < endIndex && i < this.newsList.length; i++) {
          this.visibleNewsList.push(this.newsList[i]);
        }

        this.isLoadingMore = false;
      }, 300);
    },
    confirmDelete(news) {
      this.modalMessage = `「${news.title}」を削除しますか？`; // 削除するニュースのタイトルを表示
      this.isConfirmModalVisible = true;
      this.newsToDelete = news; // 削除対象をセット
    },
    cancelDelete() {
      this.isConfirmModalVisible = false;
      this.newsToDelete = null; // 削除対象をクリア
    },
    async handleDeleteConfirmed() {
      try {
        if (this.newsToDelete) {
          // 削除処理を実行
          await this.newsService.deleteNews(this.newsToDelete.news_id);
          this.snackBarMessage = "削除しました";
        }
      } catch (error) {
        console.error('削除エラー:', error);
      } finally {
        this.isConfirmModalVisible = false;
        this.newsToDelete = null;
        this.snackBarMessage = '';
        this.$emit('update-news');
      }
    },
    async handlePatchNews(news) {
      try {
        await this.newsService.patchNews(news.news_id, news);
        this.snackBarMessage = "更新しました";
      } catch (error) {
        console.error('更新エラー:', error);
      } finally {
        this.snackBarMessage = '';
        this.$emit('update-news');
      }
    },
  },
  watch: {
    newsList: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.initializeNews();
        }
      },
    },
  },
};
</script>

<style scoped>
.news-item {
  padding: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.news-item .title-date {
  gap: 10px;
  align-items: center;
}

.news-item .info-row {
  display: flex;
  gap: 15px; /* 各要素の間にスペースを確保 */
  flex-wrap: wrap; /* 横並びでも、画面が狭ければ折り返し */
  margin-bottom: 30px; /* 上部にスペースを追加 */
}

.news-item .info-row p {
  margin: 0; /* p 要素のデフォルトのマージンを削除 */
}

button.load-more {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button.load-more:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button.load-more:hover:not(:disabled) {
  background-color: #0056b3;
}

/* メディアクエリでスマホ表示を最適化 */
@media (max-width: 768px) {
  button.patch-news, button.delete-news {
    width: 100%; /* スマホでは幅を全体に広げる */
    max-width: none; /* 最大幅制限を解除 */
  }

  .news-item .info-row {
    display: block;
    gap: 15px; /* 各要素の間にスペースを確保 */
    margin-bottom: 30px; /* 上部にスペースを追加 */
    text-align: left;
  }
}
</style>

