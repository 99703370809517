import DOMPurify from 'dompurify';

// utils.js
export function formatDate(datetime) {
    // Unixタイムスタンプをミリ秒に変換
    const timestamp = datetime * 1000;
  
    // Dateオブジェクトを生成
    const date = new Date(timestamp);
  
    // 年、月、日、時間、分、秒を取得
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    // yyyy-mm-dd hh:mm:ss 形式で返す
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

export function formatContent(content) {
  // 改行の変換
  const tempContent = content.replace(/\n/g, '<br>');
  // リンクを検出して <a> タグに変換
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const formattedContent = tempContent.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });
  // DOMPurifyでHTMLをサニタイズ（XSS攻撃から保護）
  return DOMPurify.sanitize(formattedContent);
}
  