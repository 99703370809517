<template>
  <section id="add-news">
    <h2>お知らせを追加</h2>
    <form @submit.prevent="addNews(newNews)">
      <div class="form-group">
        <label for="title">タイトル:</label>
        <input v-model="newNews.title" type="text" id="title" placeholder="タイトルを入力" required />
      </div>
      <div class="form-group">
        <label for="content">お知らせ内容:</label>
        <textarea v-model="newNews.content" id="content" placeholder="お知らせ内容を入力" required></textarea>
      </div>
      <div class="form-group">
        <label for="date">日付:</label>
        <input v-model="newNews.date" type="date" id="date" required />
      </div>
      <button type="submit">お知らせを追加</button>
    </form>
  </section>
  <!-- SnackBarの表示 -->
  <SnackBar :message="snackBarMessage" />
</template>

<script>
import newsService from '@/services/newsService';
import SnackBar from '@/components/SnackBar';

export default {
  name: 'AddNews',
  components: {
    SnackBar,
  },
  data() {
    return {
      newNews: { title: '', content: '', date: '' }, // 新しいお知らせのデータ
      snackBarMessage: '', // スナックバーのメッセージ
    };
  },
  methods: {
    async addNews(newNews) {
      if (newNews.title && newNews.content && newNews.date) {
        try {
          await newsService.postNews(newNews)
          
          // フォームをリセット
          newNews.title = '';
          newNews.content = '';
          newNews.date = '';
          this.snackBarMessage = "お知らせ情報を新規作成しました";
        } catch (error) {
          console.error('お知らせの追加に失敗しました:', error);
        } finally {
          this.$emit('update-news');
        }
      } else {
        alert('すべての項目を入力してください。');
      }
    }
  }
}
</script>

<style scoped>
#add-news {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

#add-news form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
}

label {
  font-size: 16px;
  margin-bottom: 5px;
}

input,
textarea {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-bottom: 10px;
}

textarea {
  resize: vertical;
  height: 150px;
}

button {
  padding: 12px 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

button:focus {
  outline: none;
}

input:focus, 
textarea:focus {
  border-color: #4CAF50;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
}
</style>
