import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import GreetingPage from '@/views/GreetingPage.vue';
import NewsItemPage from '@/views/NewsItemPage.vue';
import AdminPage from '@/views/AdminPage.vue';
import tokenService from '@/services/tokenService';
import TreatmentListPage from '@/views/TreatmentListPage.vue';
import TreatmentDetailPage from '@/views/TreatmentDetailPage.vue';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/greeting',
    name: 'GreetingPage',
    component: GreetingPage,
  },
  {
    path: '/news/:newsId',
    name: 'NewsItemPage',
    component: NewsItemPage,
    props: true
  },
  {
    path: '/treatment/:treatmentCategory',
    name: 'TreatmentListPage',
    component: TreatmentListPage,
    props: true
  },
  {
    path: '/treatment/:treatmentCategory/:treatmentId',
    name: 'TreatmentDetailPage',
    component: TreatmentDetailPage,
    props: true
  },
  {
    path: '/admin',
    name: 'AdminPage',
    component: AdminPage,
    beforeEnter: (to, from, next) => {
      const token = tokenService.getCookie('id_token');
      if (token && tokenService.isTokenValid(token)) {
        next();
      } else {
        next('/admin-login');
      }
    },
  },
  {
    path: '/admin-login',
    name: 'AdminLogin',
    beforeEnter: (to, from, next) => {
      // URLフラグメントからトークンを解析
      const hash = window.location.hash.slice(1);  // substr → slice に変更
      const params = new URLSearchParams(hash);
      const idToken = params.get('id_token');
      const accessToken = params.get('access_token');
  
      if (idToken && accessToken) {
        // トークンをクッキーに保存
        tokenService.setCookie('id_token', idToken, {
          path: '/',
          secure: true,
          sameSite: 'Strict',
        });
        tokenService.setCookie('access_token', accessToken, {
          path: '/',
          secure: true,
          sameSite: 'Strict',
        });
        // トークン取得後、管理者ページにリダイレクト
        next({ name: 'AdminPage' }); // ここで `next('/admin')` ではなく、`name` を使ってリダイレクト
      } else {
        // トークンがない場合、Cognitoログイン画面にリダイレクト
        tokenService.deleteCookie('id_token');
        tokenService.deleteCookie('access_token');
        const cognitoUrl = `https://ap-northeast-1xz13oba8d.auth.ap-northeast-1.amazoncognito.com/login?client_id=1pgt4sd58jo6202ddc17skg4ud&redirect_uri=https%3A%2F%2Fwww.aozora-clinic-web.com%2Fadmin-login&response_type=token&scope=email+openid+phone`;
        window.location.href = cognitoUrl;
        next(false); // リダイレクト後に次の処理を防ぐ
      }
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/NotFoundPage.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // 前のページのスクロール位置を復元
      return savedPosition;
    } else if (to.hash) {
      // ハッシュリンクがある場合
      return new Promise((resolve) => {
        setTimeout(() => {
          const element = document.querySelector(to.hash);
          if (element) {
            const offset = 130; // スクロール位置を少し上にあげる (例: 50px)
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            resolve({
              top: elementPosition - offset,
              behavior: 'smooth',
            });
          } else {
            // ハッシュターゲットが見つからない場合
            resolve({ top: 0, behavior: 'smooth' });
          }
        }, 300); // 必要ならローディング時間に応じて遅延を調整
      });
    } else {
      // デフォルトでページトップへ
      return { top: 0 };
    }
  },
});

export default router;
