<template>
  <div>
    <PageNavigation />
    <div class="main_container">
      <div class="top-section rich-section">
        <h1 style="color:#fff;">院長挨拶</h1>
        <div id="greeting">
          <div class="content-top">
            <div class="content-img">
              <img alt="Clinic Director image" src="../assets/images/director.jpg" class="display-image"/>
            </div>
            <div class="content-description">
              <p>当院は、地域の健康増進に真摯に取り組んでいます。</p>
              <p>患者様に寄り添った診療をモットーに、</p>
              <p>お気軽にご相談いただけるクリニックづくりを</p>
              <p>目指しています。</p>
              <p>適切な診断と治療、迅速な対応に最善をつくして、</p>
              <p>スタッフ一同、皆様のご来院をお待ちしています。</p>
              <p>何卒よろしくお願いいたします。</p>
              <br>
              <div class="director-name">
                <img alt="Aozora logo" src="../assets/images/aozora_logo_name.png" class="display-logo"/>
                <p>村石 康博</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="timeline-container">
        <div class="centering-container">
          <ul class="timeline">
            <li>
              <p class="timeline-date">1989年</p>
              <div class="timeline-content">
                <h3>富山医科薬科大学（現：富山大学）卒業</h3>
              </div>
            </li>
            <li>
              <p class="timeline-date">1990年</p>
              <div class="timeline-content">
                <h3>富山大学附属病院 勤務</h3>
              </div>
            </li>
            <li>
              <p class="timeline-date">1991年</p>
              <div class="timeline-content">
                <h3>みはま病院 勤務</h3>
              </div>
            </li>
            <li>
              <p class="timeline-date">2002年</p>
              <div class="timeline-content">
                <h3>上市総合病院 勤務</h3>
              </div>
            </li>
            <li>
              <p class="timeline-date">2003年</p>
              <div class="timeline-content">
                <h3>上越総合病院 勤務</h3>
              </div>
            </li>
            <li>
              <p class="timeline-date">2004年</p>
              <div class="timeline-content">
                <h3>青空クリニック 開院</h3>
              </div>
            </li>
            <li>
              <p class="timeline-date">2023年</p>
              <div class="timeline-content">
                <h3>医療法人社団青空会 青空クリニック設立</h3>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <PageFooter />
  </div>
</template>

<script>
import PageNavigation from '../components/PageNavigation.vue'
import PageFooter from '../components/PageFooter.vue'
import '../assets/styles/style.css';

export default {
  name: 'GreetingPage',
  components: {
    PageNavigation,
    PageFooter
  }
}
</script>

<style scoped>
h1 {
  color: #2c3e50;
}
.content-top {
  display: flex;
  width: 100%;
  max-width: auto;
  min-height: 400px;
  margin: 0 auto;
  justify-content: center;
}
.content-img {
  /* 見やすくするための装飾(省略可) */
  display: flex;
  text-align:center;
  width: 450px; 
}

.content-description{
  color: #fff;
  margin-left: 3vw;
  padding: 3vw 0;
  display: block;
  /* 見やすくするための装飾(省略可) */
  align-items: center;
  /* background:gray; */
  text-align:center;
  min-height: 150px;
}

.director-name {
  display: flex;
  font-weight: bolder;
  justify-content: end;
  margin-right: 20px;
}
.director-name p {
  margin-bottom: 6px;
}

.timeline-container {
  background: linear-gradient(90deg, rgb(1, 46, 94) 0%, rgba(199, 219, 248, 0.9)30%);
  display: flex;
  justify-content: center;
}

.timeline {
  list-style: none;
  margin-top: 0;
}
.timeline > li {
  margin-bottom: 60px;
}

/* for Desktop */
@media ( min-width : 640px ){
  .timeline > li {
    overflow: hidden;
    margin: 0;
    position: relative;
    width: 150%;
  }
  .timeline-date {
    width: 110px;
    float: left;
    margin-top: 20px;
  }
  .timeline-content {
    width: 50%;
    float: left;
    border-left: 3px #6bdff3 solid;
    padding-left: 30px;
    text-align: left;
  }
  .timeline-content:before {
    content: '';
    width: 12px;
    height: 12px;
    background: #25a0f1;
    position: absolute;
    left: 106px;
    top: 24px;
    border-radius: 100%;
  }
}

@media (max-width: 768px) {
  .content-top{
    display:block;
    align-items: center;
    text-align: center;
  }
  .content-description{
    margin-left: 0;
  }

  .content-img {
    justify-content: center;
    width: auto; 
  }

  .timeline {
    padding: 0px;
  }

  .display-logo {
    width: 150px;
  }

}
</style>
