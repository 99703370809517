<template>
    <div class="slider-wrapper">
      <img
        :src="pagingCategoryName"
        alt="Sliding Image"
        class="slider-image"
      />
      <div class="overlay-image">
        <h2>{{ titlePage }}</h2>
        <h3 class="vertical-txt">{{ titlePageJP }}</h3>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "InsertImage",
    props: {
      titlePage: {
        type: String,
        required: true,
      },
      titlePageJP: {
        type: String,
        required: true,
      },
    },
    computed: {
      pagingCategoryName() {
        const pagingCategory = this.titlePage;
        switch (pagingCategory) {
          case "INFORMATION":
            return require("@/assets/images/clinic_2.jpg");
          case "TREATMENT":
            return require("@/assets/images/clinic_3.jpg");
          default:
            return "";
        }
      },
    },
  };
  </script>
  
  <style scoped>
  /* 画像部分のスタイル */
  .slider-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 30%; /* 画像の幅を調整 */
    height: 100%;
    overflow: hidden; /* アニメーション中の余分な表示を隠す */
    clip-path: inset(0 0 0 100%); /* 初期状態で非表示 (左側全体を隠す) */
    animation: expand-left 0.3s ease-out forwards; /* アニメーション設定 */
  }
  
  .slider-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* アスペクト比を維持しつつ表示 */
  }
  
  @keyframes expand-left {
    0% {
      clip-path: inset(0 100% 0 0); /* 左から隠す */
    }
    100% {
      clip-path: inset(0 0 0 0); /* 完全表示 */
    }
  }
  
  /* ナビゲーションを背景画像上に重ねる */
  .overlay-image {
    position: absolute;
    top: 250px;
    left: 0;
    width: 100%;
    height: 100vh;
    color: white;
    padding: 0px;
    text-align: center;
    display: block;
  }
  
  .overlay-image h2 {
    color: white;
    font-size: min(2.5vw, 35px);
    border-bottom: 1px solid #fff;
    display: inline-block;
    padding-bottom: 5px;
  }
  
  .overlay-image h3 {
    /* transform: translate(50%, 50%) scale(0.9);
    font-feature-settings: 'palt' 0;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    white-space: nowrap;
    opacity: 1; */
    color: white;
    font-weight: bold;
    font-size: min(2.3vw, 22px);
  }
  
  /* スマートフォン対応 */
  @media (max-width: 768px) {
    .slider-wrapper {
      display: none;
    }
  }
  </style>
  