<template>
  <header class="page_header">
    <title>青空クリニック | 富山市堀 上堀駅の内科・皮膚科・泌尿器科</title>
    <div class="page_header-content">
      <h1>医療法人社団青空会</h1>
      <h1 style="margin-bottom: 0px;">青空クリニック</h1>
      <img alt="Aozora logo" src="../assets/images/aozora_logo_name.png" class="display-logo"/>
      <p>内科・皮膚科・泌尿器科</p>
    </div>
  </header>
</template>

<script>
export default {
  name: "PageHeader",
};
</script>

<style scoped>
h1 {
  padding-top: 10px;
}

.page_header {
  display: flex;
  justify-content: center; /* 横方向の中央揃え */
  align-items: center;    /* 縦方向の中央揃え */
  color: black;
  height: 50vh;          /* ビューポート全体の高さ */
  text-align: center;
  margin-top: 60px;
}

.page_header-content {
  display: flex;
  flex-direction: column; /* 縦方向に配置 */
  justify-content: center;
  align-items: center;
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.7), 0 0 16px rgba(255, 255, 255, 0.5);
}

@media (max-width: 768px) {
  .page_header {
    margin-top: 100px;
  }
}
</style>
