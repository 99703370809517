const tokenService = {
    // トークンの有効性を確認する関数
    isTokenValid(token) {
      try {
        const payload = JSON.parse(atob(token.split('.')[1])); // トークンのペイロードを解析
        const currentTime = Math.floor(Date.now() / 1000); // 現在時刻を取得（秒単位）
        return payload.exp > currentTime; // 有効期限と比較
      } catch (e) {
        console.error('Invalid token format:', e);
        return false; // 不正なトークン形式の場合
      }
    },
  
    // トークンを使ってヘッダーを作成する関数
    createHeaders() {
      const idToken = this.getCookie('id_token');  // id_tokenをCookieから取得
      const accessToken = this.getCookie('access_token');  // access_tokenをCookieから取得
  
      // トークンが無い場合
      if (!idToken || !accessToken) {
        alert('認証が必要です。再度ログインしてください。');
        window.location.href = '/admin-login';  // リダイレクト先を適切に設定
        return;
      }
  
      // idTokenの有効期限が切れている場合
      if (!this.isTokenValid(idToken)) {
        alert('認証が切れています。再度ログインしてください。');
        window.location.href = '/admin-login';  // リダイレクト先を適切に設定
        return;
      }
  
      // ヘッダーを作成して返す
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,  // Bearerトークン形式にする
      };
  
      return headers;
    },

    getCookie(name) {
        const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        return match ? match[2] : null;
    },
    
    setCookie(name, value, options = {}) {
        let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
        if (options.path) {
            cookieString += `; path=${options.path}`;
        }
        if (options.expires) {
            cookieString += `; expires=${options.expires.toUTCString()}`;
        }
        if (options.secure) {
            cookieString += '; Secure';
        }
        if (options.sameSite) {
            cookieString += `; SameSite=${options.sameSite}`;
        }
        document.cookie = cookieString;
    },

    deleteCookie(name, path = '/'){
        document.cookie = `${encodeURIComponent(name)}=; path=${path}; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    },

}
  
  export default tokenService;