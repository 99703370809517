import axios from 'axios'; // 必要に応じて Fetch API に変更可能
import tokenService from '@/services/tokenService';

// API のベース URL
const BASE_URL = 'https://i3ot0jpysf.execute-api.ap-northeast-1.amazonaws.com/dev/treatment';

const treatmentService = {
/**
   * 治療案内情報を登録する
   * @param {Object} newTreatment 更新データ
   * @returns {Promise<void>} 更新後の治療案内データ
   */
    async postTreatment(newTreatment) {
        if (newTreatment.treatment_category && 
          newTreatment.treatment_category_name && 
          newTreatment.treatment_name && 
          newTreatment.symptoms_description) {
          try { 
            const headers = tokenService.createHeaders()

            const safeValue = (value) => value ?? ''; // nullやundefinedなら空文字列を返す
            const safeValueList = (value) => value ?? []; // nullやundefinedなら空配列を返す
            // 治療案内をAPIに送信
            const response = await axios.post(`${BASE_URL}/${newTreatment.treatment_category}`, 
              JSON.stringify({
                treatment_category_name: newTreatment.treatment_category_name,
                treatment_name: newTreatment.treatment_name,
                symptoms_description: newTreatment.symptoms_description,
                title: safeValue(newTreatment.title),
                content: safeValue(newTreatment.content),
                details: safeValueList(newTreatment.details),
              }), 
              { headers }
            );

            if (response.data.statusCode != 200) {
              console.error('追加に失敗しました:', response.data.body);
              throw new Error('治療案内情報の追加に失敗しました');              
            }
            // 成功メッセージ
            console.log('治療案内が追加されました:', response.data);

            return JSON.parse(response.data.body);
  
          } catch (error) {
            console.error('API Error:', error);
            alert('治療案内の追加に失敗しました。再度試してください。');
            throw error;
          }
        } else {
          alert('すべての項目を入力してください。');
          throw new Error('必須項目が入力されていません');
        }
    },

/**
 * 治療案内の画像を登録する関数
 * @param {string} treatmentCategory
 * @param {string} treatmentId
 * @param {string} fileName
 * @param {File} imageFile - アップロードする画像ファイル
 */
    async putTreatmentImage(treatmentCategory, treatmentId, fileName, imageFile) {
      try{
        const base64Image = await this.fileToBase64(imageFile);
        const fileExtension = fileName.split('.').pop().toLowerCase();
        const savedFileName = 'TreatmentImage.' + fileExtension;
        const headers = tokenService.createHeaders()
        const response = await axios.put(`${BASE_URL}/${treatmentCategory}/${treatmentId}/treatment_image?name=${encodeURIComponent(savedFileName)}`,
          base64Image,
          { headers }
        );
        if (response.data.statusCode != 200) {
          console.error('画像登録に失敗しました:', response.data);
          throw new Error('画像の登録に失敗しました');
        }

        console.log('画像が正常に登録されました:', response.data);
        return response.data;
      } catch (error) {
        console.error('画像登録に失敗しました:', error);
        throw error;
      }
    },

/**
 * ファイルをBase64に変換する関数
 * @param {File} file - 入力ファイル
 * @returns {Promise<string>} Base64エンコードされた文字列
 */
    async fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(',')[1]); // Base64部分のみ抽出
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    },

/**
 * 治療案内情報をリスト取得する関数
 * @param {string} treatmentCategory - 治療案内カテゴリー
 * @returns {Promise<Array>} 治療案内情報の配列
 */
    async getTreatmentList(treatmentCategory) {
        let responseBody = [];
        try {
            const response = await axios.get(`${BASE_URL}/${treatmentCategory}`);
            
            responseBody = response.data.body;
            // 成功メッセージ
            console.log('治療案内の取得に成功しました:', response.data);
            if (response.data.statusCode != 200) {
              console.error('取得に失敗しました:', responseBody);
              throw new Error('治療案内情報のリスト取得に失敗しました');              
            }
            // レスポンスが配列であるか確認
            if (Array.isArray(responseBody)) {
                // 必要なデータを抽出
                return responseBody.map(treatment => {
                    return {
                        treatment_category: treatment.treatment_category,
                        treatment_category_name: treatment.treatment_category_name,
                        treatment_id: treatment.treatment_id,
                        treatment_name: treatment.treatment_name,
                    };
                });
            } else {
                console.error('取得したデータが配列ではありません:', responseBody);
                return [];
            }
        } catch (error) {
            console.error('治療案内情報の取得に失敗しました:', error);
            throw error;
        }
    },


/**
 * 治療案内情報を取得する関数
 * @param {string} treatmentCategory - 治療案内カテゴリー
 * @param {string} treatmentId - 治療案内ID
 * @param {boolean} [admin=false] - 管理者フラグ。trueの場合、詳細データを含むレスポンスを返す。
 * @returns {Promise<Object>} ニュース情報のオブジェクト
 */
    async getTreatment(treatmentCategory, treatmentId, admin = false) {
      try {
        const response = await axios.get(`${BASE_URL}/${treatmentCategory}/${treatmentId}`);
        
        // デバッグ: レスポンス全体を確認
        console.log('APIレスポンス:', response.data);

        // bodyが既にオブジェクトならJSON.parse不要
        const treatment = response.data.body;
        if (response.data.statusCode != 200) {
          console.error('取得に失敗しました:', treatment);
          throw new Error('治療案内情報の取得に失敗しました');              
        }
        if (!treatment) {
          throw new Error('治療案内情報が見つかりません');
        }

        const safeValue = (value) => value ?? ''; // nullやundefinedなら空文字列を返す
        const safeValueList = (value) => value ?? []; // nullやundefinedなら空配列を返す
        // 必要なデータを抽出
        if (admin) {
          // 管理者用データ
          return {
            treatment_category: treatment.treatment_category,
            treatment_category_name: treatment.treatment_category_name,
            treatment_id: treatment.treatment_id,
            treatment_name: treatment.treatment_name,
            symptoms_description: treatment.symptoms_description,
            title: safeValue(treatment.title),
            content: safeValue(treatment.content),
            details: safeValueList(treatment.details),
            image_path: safeValue(treatment.image_path),
            create_info: treatment.create_info,
            update_info: treatment.update_info,
          };
        } else {
          // 一般ユーザー用データ
          return {
            treatment_category: treatment.treatment_category,
            treatment_category_name: treatment.treatment_category_name,
            treatment_id: treatment.treatment_id,
            treatment_name: treatment.treatment_name,
            symptoms_description: treatment.symptoms_description,
            title: safeValue(treatment.title),
            content: safeValue(treatment.content),
            details: safeValueList(treatment.details),
            image_path: safeValue(treatment.image_path),
          };
        }
      } catch (error) {
        console.error('治療案内情報の取得に失敗しました:', error);
        throw error;
      }
    },

/**
   * 治療案内情報を更新する
   * @param {string} treatmentCategory - 治療案内カテゴリー
   * @param {string} treatmentId 治療案内ID
   * @param {Object} updatedTreatmentData 更新データ
   */

    async patchTreatment(treatmentCategory, treatmentId, updatedTreatmentData) {
      let responseBody = null;
      try {
          const headers = tokenService.createHeaders()
          
          console.log(`治療案内情報を更新します`, updatedTreatmentData)

          const safeValue = (value) => value ?? ''; // nullやundefinedなら空文字列を返す
          const safeValueList = (value) => value ?? []; // nullやundefinedなら空配列を返す
          // PATCH リクエスト
          const response = await axios.patch(`${BASE_URL}/${treatmentCategory}/${treatmentId}`, 
            JSON.stringify({
              treatment_name: updatedTreatmentData.treatment_name,
              symptoms_description: updatedTreatmentData.symptoms_description,
              title: safeValue(updatedTreatmentData.title),
              content: safeValue(updatedTreatmentData.content),
              details: safeValueList(updatedTreatmentData.details),
              }), 
              { headers }
          );
          responseBody = response.data.body;
          if (response.data.statusCode != 200) {
            console.error('更新に失敗しました:', responseBody);
            throw new Error('治療案内情報の更新に失敗しました');              
          }
          // 成功メッセージ
          console.log('治療案内の更新に成功しました:', response.data);
      } catch (error) {
          console.error(`治療案内情報 (ID: ${treatmentId}) の更新に失敗しました:`, error);
          throw error;
      }
    },
    
  /**
   * 治療案内を削除する
   * @param {string} treatmentCategory - 治療案内カテゴリー
   * @param {string} treatmentId 治療案内ID
   */
    async deleteTreatment(treatmentCategory, treatmentId) {
      let responseBody = null;
      try {
          const headers = tokenService.createHeaders()
          const response = await axios.delete(`${BASE_URL}/${treatmentCategory}/${treatmentId}`, { headers });

          responseBody = response.data.body;
          if (response.data.statusCode != 200) {
            console.error('削除に失敗しました:', responseBody);
            throw new Error('治療案内情報の削除に失敗しました');              
          }
          // 成功メッセージ
          console.log(`治療案内 (ID: ${treatmentId}) を削除しました。`);
      } catch (error) {
          console.error(`治療案内 (ID: ${treatmentId}) の削除に失敗しました:`, error);
          throw error;
      }
    },
};

export default treatmentService;