<template>
    <div v-if="visible" class="snackbar" :class="{ show: visible }">
      {{ message }}
    </div>
  </template>
  
  <script>
  export default {
    name: "SnackBar",
    props: {
      message: {
        type: String,
        required: true,
      },
      duration: {
        type: Number,
        default: 3000, // デフォルトで3秒表示
      },
    },
    data() {
      return {
        visible: false,
      };
    },
    methods: {
      show() {
        this.visible = true;
        setTimeout(() => {
          this.visible = false;
        }, this.duration);
      },
    },
    watch: {
      message: {
        immediate: true,
        handler() {
          if (this.message) {
            this.show();
          }
        },
      },
    },
  };
  </script>
  
  <style scoped>
  .snackbar {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .snackbar.show {
    opacity: 1;
  }
  </style>
  