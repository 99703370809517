<template>
    <!-- ローディング画面 -->
    <LoadingScreen v-if="isLoading" />

    <div v-else>
      <!-- メイン画面 -->
      <div>
        <PageNavigation />
        <!-- お知らせ情報 -->
        <div class="margin-section">
            <div>
                <InsertImage titlePage="INFORMATION" titlePageJP="お知らせ"/>
            </div>
            <div v-if="news" class="half-content">
                <article>                   
                    <div class="post-news">
                        <p><small>{{ news.date }}</small></p>
                        <div>
                            <p style="font-weight: bolder;">{{ news.title }}</p>
                            <p v-html="formatContent(news.content)" class="post-content"></p>
                        </div>
                    </div>
                </article>
            </div>
            <div v-else><p>該当するお知らせ情報はありません。</p></div>
        </div>
        <PageFooter />
      </div>
    </div>
  </template>

<script>
import { formatContent } from '@/utils/utils';

import PageNavigation from '@/components/PageNavigation.vue';
import PageFooter from '@/components/PageFooter.vue';
import newsService from '@/services/newsService';
import LoadingScreen from '@/components/LoadingScreen.vue';
import InsertImage from '@/components/InsertImage.vue';

export default {
    name: 'HomePage',
    components: {
      PageNavigation,
      PageFooter,
      LoadingScreen,
      InsertImage,
    },
    props: {
        newsId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            news: null, // 選択されたお知らせデータ
            isLoading: true // ローディング状態
        };
    },
    created() {
        this.fetchNews();
    },
    methods: {
        formatContent,
        async fetchNews() {
            try {
                const newsId = this.$route.params.newsId;
                console.log('newsId: ',newsId);
                this.news = await newsService.getNews(newsId); 
            } catch (error) {
                console.error('お知らせの読み込みに失敗しました:', error);
            } finally {
                this.isLoading = false; // ローディング終了
            }
        },
    }
  };
</script>

<style scoped>

.post-news {
  display: flex;
  justify-content: start;
  align-items:flex-start;
  text-align: left;
  height: auto;
  margin: 30px 3vw;
  gap: 30px;
}

.post-content {
    margin-top: 5vh;
}

/* スマートフォン対応 */
@media (max-width: 768px) {
    .post-news {
        display: block;
        align-items:flex-start;
        text-align: left;
        height: auto;
        margin: 30px 5vw;
        gap: 30px;
    }
}
</style>