<template>
  <footer class="page-footer">
    <p>© 2024 青空クリニック. All rights reserved.</p>
  </footer>
</template>

<script>
export default {
  name: "PageFooter",
};
</script>

<style scoped>
.page-footer {
  font-size: 0.8rem; /* テキストサイズを小さくする */
  color: #666;
  text-align: center;
  padding: 1em 0;
}
</style>