<template>
  <div>
    <div v-for="(news, index) in visibleNewsList" :key="index" class="news-item">
      <div class="title-date">
        <p><small>{{ news.date }}</small></p>
        <h3 @click="selectNews(news)">{{ news.title }}</h3>
      </div>
    </div>
    <button v-if="hasMoreNews" @click="showMore" class="load-more">
      {{ isLoadingMore ? '読み込み中...' : 'さらに表示' }}
    </button>
  </div>
</template>

<script>

export default {
  name: 'NewsItem',
  props: {
    newsList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      visibleNewsList: [], // 表示するニュースのリスト
      visibleCount: 5, // 初期表示件数
      isLoadingMore: false, // ローディング中フラグ
    };
  },
  computed: {
    hasMoreNews() {
      return this.newsList && this.visibleNewsList.length < this.newsList.length;
    },
    sortedNewsList() {
      // 日付順にソートされたリストを返す
      return this.newsList.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
    },
  },
  methods: {
    initializeNews() {
      // sortedNewsList を使って初期表示
      if (this.sortedNewsList) {
        this.visibleNewsList = this.sortedNewsList.slice(0, this.visibleCount);
      }
    },
    showMore() {
      if (!this.sortedNewsList) return;

      this.isLoadingMore = true;
      setTimeout(() => {
        const startIndex = this.visibleNewsList.length;
        const endIndex = startIndex + this.visibleCount;

        for (let i = startIndex; i < endIndex && i < this.sortedNewsList.length; i++) {
          this.visibleNewsList.push(this.sortedNewsList[i]);
        }

        this.isLoadingMore = false;
      }, 300);
    },
    selectNews(news) {
      this.$router.push(`/news/${news.news_id}`);
    }
  },
  watch: {
    newsList: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.initializeNews();
        }
      },
    },
  },
};
</script>

<style scoped>
.news-item {
  padding: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.news-item .title-date {
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.title-date h3 {
  padding-left: 1.5em;
  transition: .15s ease-out;
  -webkit-transform: translateZ(0);
  margin-bottom: 0;
}

.title-date p {
  margin-bottom: 0;
}

.title-date h3:hover {
  color: #a3bbce;
}

button.load-more {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button.load-more:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button.load-more:hover:not(:disabled) {
  background-color: #0056b3;
}

@media (max-width: 768px) {

}

</style>
