<template>
    <div class="loading-screen">
      <p>Loading...</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LoadingScreen',
  };
  </script>
  
  <style scoped>
  .loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.5em;
    color: gray;
    background-color: #f8f8f8;
  }
  </style>
  